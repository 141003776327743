<script setup>
import { openModal } from '@kolirt/vue-modal';
import { computed, ref } from 'vue';
import { useCasinoStore } from '@/stores/casino';
import Login from '@/components/modals/Login.vue';
import Register from '@/components/modals/Register.vue';
import GameImage from './GameImage.vue';
import Loader from '@/components/casino/LoaderComponent.vue';

const casinoStore = useCasinoStore();

// Show Register Modal
const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() => { });
};

// Show Login Modal
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() => { });
};

let show_more_loading = ref({});

// Toggle Show More
const showMoreToggle = (provider) => {
	show_more_loading.value[provider] = true;

	// Get all provider's games length
	var all_games_count = casinoStore.getGames[provider].length;

	// Toggle show more
	casinoStore.showMoreToggle(provider).then(() => {
		show_more_loading.value[provider] = false;
	}).catch(() => {
		show_more_loading.value[provider] = false;
	});

	// If provider is expanded or all provider's games are 13 or less games do not scroll to provider's top
	if (casinoStore.getShowMoreProvider.includes(provider) || all_games_count <= 13) {
		return;
	}

	// Get element
	const provider_element = document.getElementById('provider_' + provider);

	// Get the element's position relative to the viewport
	const rect = provider_element.getBoundingClientRect();

	// Calculate the new scroll position with a 10-pixel offset at the top
	const scroll_top_offset = window.scrollY + rect.top - 30;

	// Scroll to the new position smoothly
	window.scrollTo({
		top: scroll_top_offset,
		behavior: "smooth"
	});
};

// Check if left container is hidden

const isLeftContainerHidden = computed(() => {
	if (window.innerWidth >= 0 && window.innerWidth <= 991) return true;
	else return false;
});

const snapshotGamesPerProvider = computed(() => {
	if (window.innerWidth >= 0 && window.innerWidth <= 991) return 4;
	else if (window.innerWidth >= 992 && window.innerWidth <= 1024) return 7;
	else if (window.innerWidth >= 1025 && window.innerWidth <= 1359) return 9;
	else if (window.innerWidth >= 1360 && window.innerWidth <= 1599) return 11;
	else return 13;
});

const loadMoreVisibilityChanged = (is_visible) => {
	if (is_visible && !casinoStore.getGamesLoading && !casinoStore.getGamesLoadingMore && casinoStore.hasNextPage) {
		casinoStore.loadMoreGames();
	}
};

const getTotalTablesPerCategory = (brand_slots) => {
	let provider_id = null;
	if (brand_slots.length > 0 && brand_slots[0] != null) {
		provider_id = brand_slots[0].slot_provider_id;
	} else {
		// Return 11 as fallback to show the "More" button
		return 11;
	}

	if (Object.keys(casinoStore.getTotalGamesPerProvider).length == 0 || Object.hasOwnProperty.call(casinoStore.getTotalGamesPerProvider, provider_id) === false) {
		return 11;
	}

	return casinoStore.getTotalGamesPerProvider[provider_id];
};
</script>
<template>
	<div class="container-lg casino-container-lg">
		<!-- Casino Games Start -->
		<div :id="'provider_' + brand_name" class="casino-feature-games" v-for="(brand_slots, brand_name) in casinoStore.getGames" :key="brand_name">
			<div class="title">
				<h3>{{ brand_name }}</h3>
			</div>
			<div class="casino-feature-outer">
				<!-- Left Container Start -->
				<div v-if="!isLeftContainerHidden" class="large-feature-game">
					<div class="feature-image feature-image-large">
						<GameImage :game="brand_slots[0]" :size="'large'"/>
					</div>
					<div class="feature-title">
						<h4 class="ellipsis">{{ brand_slots[0].name }}</h4>
					</div>
				</div>
				<!-- Left Container End -->

				<!-- Right Container Start -->
				<div class="feature-games-main" :class="casinoStore.getShowMoreProvider.includes(brand_name) ? 'show_all' : ''">
					<template v-for="game, index in brand_slots" :key="index">
						<div v-if="index > 0 || isLeftContainerHidden" class="featured-game-inner">
							<div class="feature-image">
								<GameImage :game="game"/>
							</div>
							<div class="feature-title">
								<h4 class="ellipsis">{{ game.name }}</h4>
							</div>
						</div>
					</template>
				</div>
				<!-- Right Container End -->
			</div>

			<!-- Show More/Less Button Start -->
			<div class="view-all-btn" :class="getTotalTablesPerCategory(brand_slots) <= snapshotGamesPerProvider ? 'view-all-btn-full-width' : ''">
				<button	v-if="getTotalTablesPerCategory(brand_slots) > snapshotGamesPerProvider" @click="showMoreToggle(brand_name)" class="btn-small view-all-button">
					<div v-if="show_more_loading[brand_name] == true" class="loader-main"></div>
					<span v-else>{{ casinoStore.getShowMoreProvider.includes(brand_name) ?	$t('general.less') : $t('general.more') }}</span>
				</button>
			</div>
			<!-- Show More/Less Button End -->
		</div>
		<div v-observe-visibility="loadMoreVisibilityChanged" style="height: 20px;"></div>

		<Loader v-show="casinoStore.getGamesLoadingMore == true"/>

		<!-- Casino Games End -->
	</div>
</template>
<style scoped>
.view-all-btn {
	text-align: right;
}

.view-all-btn:before {
	left: 0;
}

html[dir="rtl"] .view-all-btn {
	text-align: left !important;
}

html[dir="rtl"] .view-all-btn:before {
	right: 0 !important;
}

.text-color-and-align {
	text-align: center;
	color: #fff;
}

.casino-feature-games:first-child {
	padding-top: 30px !important;
}

@media (min-width: 540px) and (max-width: 670px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (min-width: 670px) and (max-width: 991px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer {
		grid-template-columns: 1fr;
	}
}

.container-lg {
	padding-bottom: 30px;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.casino-feature-games {
	overflow-x: hidden;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
}

.view-all-btn-full-width:before {
	width: 100% !important;
}

.feature-image-large {
	margin-left: auto;
	margin-right: auto;
}
</style>
